import React, { useEffect, useState } from "react";
import { BrowserMultiFormatReader, NotFoundException } from "@zxing/library";
import axios from "axios";

function QrScanner() {
  const [result, setResult] = useState("");
  const [message, setMessage] = useState("");
  const [scanning, setScanning] = useState(false);
  const [codeReader, setCodeReader] = useState(null);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(null);
  const [deviceId, setDeviceId] = useState("");

  useEffect(() => {
    const reader = new BrowserMultiFormatReader();
    setCodeReader(reader);

    reader
      .listVideoInputDevices()
      .then((videoInputDevices) => {
        // Filtrer les périphériques pour trouver la caméra arrière
        const rearCamera = videoInputDevices.find((device) =>
          device.label.toLowerCase().includes("back")
        );
        if (rearCamera || videoInputDevices.length > 0) {
          setDeviceId(
            rearCamera ? rearCamera.deviceId : videoInputDevices[0].deviceId
          );
        }
      })
      .catch((err) => console.error(err));

    return () => {
      if (reader) {
        reader.reset();
      }
    };
  }, []);

  const startScan = () => {
    if (codeReader && deviceId) {
      setScanning(true);
      setMessage(""); // Clear previous message
      setIsSuccess(null); // Reset success state
      setError(""); // Reset error state

      codeReader.decodeFromVideoDevice(deviceId, "video", (result, err) => {
        if (result) {
          const scannedResult = result.getText();
          setResult(scannedResult);
          verifyTicket(scannedResult);
          setScanning(false);
        }
        if (err && !(err instanceof NotFoundException)) {
          console.error(err);
          setError("An error occurred while scanning.");
          setScanning(false);
        }
      });
    } else {
      console.error("No deviceId available for scanning");
    }
  };

  const verifyTicket = async (ticketCode) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://youkiff.com/api/v1/verify",
        { ticket: ticketCode, event: "sethlo" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { success, message } = response.data;
      setIsSuccess(success);
      setMessage(message);
    } catch (err) {
      setIsSuccess(false);
      setMessage("Error verifying QR code");
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="w-full flex flex-col items-center justify-center">
        <h2 className="text-2xl font-bold mb-4">Scan QR Code</h2>
        {!scanning && !error && (
          <button
            onClick={startScan}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Start Scan
          </button>
        )}
        {scanning && !error && (
          <video
            id="video"
            className="w-full h-screen object-cover"
            style={{ transform: "scaleX(-1)" }}
          ></video>
        )}
        {message && (
          <div
            className={`mt-4 p-4 rounded flex items-center ${
              isSuccess
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            }`}
          >
            {isSuccess ? (
              <svg
                className="w-6 h-6 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
            <span>{message}</span>
          </div>
        )}
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>
    </div>
  );
}

export default QrScanner;
