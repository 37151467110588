import React from "react";
import { Link } from "react-router-dom";

function Dashboard() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-md text-center">
        <h1 className="text-2xl font-bold mb-6">Welcome to the Dashboard</h1>
        <Link
          to="/scan"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Scan QR Code
        </Link>
      </div>
    </div>
  );
}

export default Dashboard;
